import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('search', function (data, opts = {}) {
    let instance = null

    return {
      search: false,
      results: [],
      get hasResults() {
        return this.results.length > 0
      },
      inResults(ids) {
        if (!Array.isArray(ids)) ids = [ids]
        return this.results.some((result) => ids.includes(result.item.id))
      },
      onInput(event) {
        if (!instance) return
        if (!event.target.value || event.target.value.trim().length === 0) return (this.search = false)
        this.search = true
        this.results = instance.search(event.target.value)
      },
      async init() {
        const Fuse = await import('fuse.js').then((mod) => mod.default).catch(console.error)
        instance = new Fuse(data, {...opts, minMatchCharLength: 2})
      },
    }
  })
})()
