import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('magicField', function (type, ...args) {
    const actions = {
      sum(elem, items) {
        const form = elem.form
        const selectors = items.map((item) =>
          form.querySelector(`[name="${item}"]`)
        )
        const setValues = () => {
          elem.value = selectors.reduce((acc, el) => {
            const val = el.value
            if (val === '') {
              return acc
            }
            //console.log(new NumberParser(el.lang).parse(val))
            return acc + parseFloat(val.replace(',', '.'))
          }, 0)
        }

        setValues()
        selectors.forEach((el) => {
          el.addEventListener('input', setValues)
        })
      },
    }
    return {
      init() {
        const field = this.$root
        actions[type](field, args)
      },
    }
  })
})()
