import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('scrollbars', function (opts) {
    return {
      async init() {
        const [SimpleBar] = await Promise.all([
          import('simplebar').then((mod) => mod.default),
          import('simplebar/dist/simplebar.css'),
        ]).catch(console.error)

        new SimpleBar(this.$root, opts)
      },
    }
  })
})()
