import Alpine from 'alpinejs'

export default (function () {
  let promise = null

  Alpine.data('marquee', (conf) => ({
    paused: false,
    instance: null,
    async load() {
      let items = [...this.$el.children].map(node => node.parentElement.removeChild(node))
      const [Plugin, loop] = await promise
      const builders = items.map(html => () => {
        const el = document.createElement('div')
        el.innerHTML = html.outerHTML
        return el
      })

      this.instance = new Plugin(this.$root, conf)
      loop(this.instance, builders)

      this.$el.addEventListener('mouseenter', () => this.paused = true)
      this.$el.addEventListener('mouseleave', () => this.paused = false)

      this.$el.addEventListener('touchstart', () => this.paused = true)
      this.$el.addEventListener('touchend', () => this.paused = false)

      this.$watch('paused', (val) => {
        val ? this.instance.setRate(0) : this.instance.setRate(conf.rate)
      })
    },
    init() {
      promise = import('dynamic-marquee').then(mod => [mod.Marquee, mod.loop]).catch(e => console.log(e))
    },
    destroy() {
      this.instance && this.instance.clear()
    },
  }))
})()