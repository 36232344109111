import Alpine from 'alpinejs'

export default (() => {
  Alpine.data('form', function () {
    return {
      currentlyEditing: false,
      async init() {
        const form = this.$root
        /** @type {import("dropzone").DropzoneOptions} */
        const dropzoneOptions = {
          previewsContainer: '.dz-preview-container',
        }
        const [ConditionalLogic, Repeater, FetchManager, DropzoneHandler] = await import(
          'js-pimcore-formbuilder'
        ).then((mod) => [
          mod.ConditionalLogic,
          mod.Repeater,
          mod.FetchManager,
          mod.DropzoneHandler,
        ])

        new DropzoneHandler(form, { dropzoneOptions })
        new ConditionalLogic(form, {
          elementTransformer: {
            show: function (el) {
              el.closest('.form-row').classList.remove('hidden')
            },
            hide: function (el) {
              el.closest('.form-row').classList.add('hidden')
            },
            addRequiredState: (el) => {
              el.setAttribute('required', 'required');

              if (el.labels.length) {
                [...el.labels].forEach((label) => {
                  label.querySelector('.form-required-marker')?.classList?.remove('hidden')
                })
              }

              if (el.closest('.ajax-form') && el.closest('.ajax-form').classList.contains('ajax-form-submitted')) {
                  el.closest('.form-row').classList.add('form-invalid');
              }
            //   if (el.closest('.form-group') && el.closest('.form-group').querySelector('.form-label')) {
            //       // form-group field
            //       el.closest('.form-group').querySelector('.form-label').classList.add('required');
            //   } else if (el.labels.length) {
            //       // default
            //       el.labels[0].classList.add('required');
            //   }
            },
            removeRequiredState: (el) => {
              el.removeAttribute('required')
              el.closest('.form-row').classList.remove('form-invalid')

              if (el.labels.length) {
                [...el.labels].forEach((label) => {
                  label.querySelector('.form-required-marker')?.classList?.add('hidden')
                })
              }

              // if (el.closest('.form-group')) {
              //   // form-group field
              //   let legendField = el
              //     .closest('.form-group')
              //     .querySelector('.col-form-legend')
              //   if (legendField) {
              //     legendField.classList.add('required')
              //   }
              //   let invalidFeedback = el
              //     .closest('.form-group')
              //     .parentNode.querySelector('.form-invalid-message')
              //   if (invalidFeedback) {
              //     invalidFeedback.remove()
              //   }
              // } else if (el.labels.length) {
              //   // default
              //   el.labels[0].classList.remove('required')
              //   let invalidFeedback = el
              //     .closest('.form-row')
              //     .querySelector('.form-invalid-message')
              //   if (invalidFeedback) {
              //     invalidFeedback.remove()
              //   }
              // }
            },
          },
          hideElementClass: 'hidden',
        })
        const repeaterInstance = new Repeater(form, {
          containerBlockClass: '.form-block',
          onAdd: (container, newForm, cb) => {
            const parser = new DOMParser()
            const newBlock = parser.parseFromString(newForm, 'text/html').body.firstChild
            container.insertBefore(newBlock, container.querySelector('.add-block'))
            cb(newBlock)
          },
          allocateRemoveBlockElement: function (block, element) {
            block.querySelector('.form-block').appendChild(element)
            element.addEventListener('click', (ev) => {
              const container = element.closest(this.containerClass)
              repeaterInstance.onRemove(ev, container)
              // remove empty immediate children of the container
              container.childNodes.forEach((block) => {
                if (block.children.length === 0) block.remove()
              })
            })
          },
          renderCreateBlockElement: (block, classes, text) => {
            let temp = document.createElement('div')
            temp.innerHTML = `
            <button class="${classes} relative cursor-pointer text-green-base text-4 leading-8 w-full px-5">
              <div class="flex gap-4 items-center w-full px-2.5">
                <div class="flex items-center justify-center w-8 h-8 border border-current rounded-full">
                  +
                </div>
                <div class="text-3">${text}</div>
                <div class="border-t border-current grow"></div>
              </div>
            </button>`
            return temp.firstElementChild
          },
          renderRemoveBlockElement: (block, classes, text) => {
            let temp = document.createElement('div')
            temp.innerHTML = `<button class="${classes} flex items-center justify-center w-8 h-8 text-red-base text-4 leading-8 border border-current absolute top-1/2 right-0 -mr-4 -translate-y-1/2 rounded-full z-10" title="${text}">
              ×
            </button>`
            return temp.firstElementChild
          },
        })
        new FetchManager(form, {
          onGeneralError: (generalErrorMessages) => {
            console.warn('Form error: ', generalErrorMessages)
            const errorEl = document.createElement('div')
            errorEl.classList.add('error-message', 'text-red-600', 'mx-6')
            errorEl.innerText =
              'Momentan scheint es Probleme mit dem Server zu geben. Bitte versuchen Sie es erneut oder melden Sie sich bei dem Administrator.'
            form.after(errorEl)
          },
          onFail: ({validation_errors}, form) => {
            if (validation_errors) {
              const field = form.querySelector('#' + Object.keys(validation_errors)[0])
              field.focus()
              // scroll to the field
              field.scrollIntoView({
                behavior: 'smooth',
              })
            }
          },
          onSuccess(messages, redirect) {
            let target
            if (redirect) {
              if (redirect.substring(0, 1) === '/')
                target = new URL(window.location.origin + redirect)
              else target = new URL(redirect)
              window.location = target
            } else if (messages.length) {
              let errors = false
              const html = messages
                .map(({ type, message }) => {
                  if (type !== 'success') {
                    errors = true
                    return ''
                  }
                  return message
                })
                .join('\n')

              if (errors) {
                appendErrors(
                  form,
                  'Momentan scheint es Probleme mit dem Server zu geben. Bitte versuchen Sie es erneut oder melden Sie sich bei dem Administrator.'
                )
              } else {
                form.outerHTML = html
              }
            }
          },
          elementTransformer: {
            addValidationMessage: (form, field, messages) => {
              form.classList.add('error-form')
              appendErrors(field, messages)
            },
            removeFormValidations: (form) => {
              form.classList.remove('error-form')
              form
                .querySelectorAll('.field-error')
                .forEach((el) =>
                  el.classList.remove('field-error', 'border-red-600')
                )
              form
                .querySelectorAll('.error-message')
                .forEach((el) => el.remove())
            },
          },
        })
      },
    }
  })
})()

function appendErrors(field, messages) {
  let additionalClasses = []
  let wrapper = field.closest('.form-row')
  if (!wrapper) {
    wrapper = field.closest('form')
    additionalClasses = ['px-2.5', 'pt-2.5']
  }
  wrapper.classList.add('field-error', 'border-red-600')
  ;[...messages].forEach((message) => {
    const errorEl = document.createElement('div')
    errorEl.classList.add('error-message', 'text-red-600', 'leading-none', 'pt-2')
    if (additionalClasses.length) errorEl.classList.add(...additionalClasses)
    errorEl.innerText = message
    wrapper.appendChild(errorEl)
  })
}
