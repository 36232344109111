import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('accordion', function (open = false, skip = false) {
    return {
      open,
      skip,
      height: 0,
      setOpen() { this.open = true },
      getHeight() { return this.$refs.content.scrollHeight },
      init() {
        // needed for binding to work properly
        Alpine.nextTick(() => {
          this.height = this.getHeight()

          if (this.skip) return;
          // listen to size changes
          try {
            new ResizeObserver(() => {
              const newHeight = this.getHeight()
              if (this.height !== newHeight) this.height = newHeight
            }).observe(this.$refs.content)
          } catch (error) {
            // no resize observer possible
          }
        })
      },
      toggle: {
        ['@click.prevent']() { this.open = !this.open },
      },
      content: {
        ['x-ref']: 'content',
        [':style']() { return { 'max-height': this.skip ? 'none' : (this.open ? this.height + 'px' : 0) } },
      },
      rotatingIndicator45: {
        [':class']: '{ "rotate-45": open }',
      },
      rotatingIndicator90: {
        [':class']: '{ "rotate-90": open }',
      },
      'rotatingIndicatorMinus90': {
        [':class']: '{ "-rotate-90": open }',
      },
    }
  })
})()