import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('tabs', function ({active = null, skip = false}) {
    return {
      active,
      skip,
      init() {
        const hash = location.hash.substring(1)
        if (skip || !hash || hash === '' || !document.getElementById(hash)) {
          return
        }

        this.active = hash
        
        if (this.$refs.tabs) {
          this.$refs.tabs.querySelector(`[href="#${hash}"]`).scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'})
        }
      },
      goTo(id, e = null) {
        if (this.skip) {
          return
        }

        if (document.getElementById(id)) {
          const target = document.getElementById(id)
          target.removeAttribute('id')
          location.hash = id
          target.setAttribute('id', id)
        }

        this.active = id
        if (e) {
          e.target.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'})
        }
      }
    }
  })
})()
